import { CurrencyPipe, DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { ColumnType } from 'src/app/shared/Column';

@Pipe({
  name: 'columnParse',
})
export class ColumnParsePipe implements PipeTransform {
  constructor(private datePipe: DatePipe, private currencyPipe: CurrencyPipe) {}

  transform(
    field: unknown,
    datum: any,
    colType: ColumnType | undefined
  ): string | null {
    let parsed;
    if (field instanceof Function) {
      parsed = field(datum);
    } else {
      parsed = datum[field as keyof typeof datum];
      // console.log(datum, field, parsed);
    }

    switch (colType) {
      case ColumnType.Date:
        return this.datePipe.transform(parsed, "MMM dd, yyyy', ' hh:mm a");
      case ColumnType.Boolean:
        return parsed ? 'Yes' : 'No';
      default:
        return parsed;
    }
  }
}
