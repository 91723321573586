import { Injectable } from '@angular/core';
import { UIActions } from 'src/app/models/UIActions';
import { UIs } from 'src/app/models/UIs';
import Store, { AppKey } from '../../store';
import { User } from 'src/app/models/User';
import { UIRoutes } from 'src/app/models/UIRoutes';
import { AuthorizationConfig } from 'src/app/models/AuthorizationConfig';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor() {}

  hasPermission(ui: UIs, action?: UIActions): boolean {
    const user = new Store().get(AppKey.USER) as User;
    if (user?.IsSeeded && !user.IsReadOnlyAdmin) return true;
    const role = user?.Role;
    if (!role) return false;
    const uiFound = role.UIs.find((access) => access.Name == ui);
    if (!uiFound) return false;
    if (action) {
      const actionFound = uiFound.Actions.find(
        (access) => access.Name == action
      );
      return !!actionFound;
    } else return true;
  }

  hasActionPermission(action: UIActions): boolean {
    const user = new Store().get(AppKey.USER) as User;
    if (user?.IsSeeded && !user.IsReadOnlyAdmin) return true;
    const role = user?.Role;
    if (!role) return false;
    if (action) {
      const actionFound = role.UIs.flatMap(({ Actions }) => Actions).find(
        (access) => access.Name == action
      );
      return !!actionFound;
    } else return false;
  }

  canAccessRoute(route: UIRoutes): boolean {
    if(route=='home')
     return true;
    const user = new Store().get(AppKey.USER) as User;
    if (user?.IsSeeded && !user.IsReadOnlyAdmin) return true;
    const role = user?.Role;
    if (!role) return false;
    const ui = AuthorizationConfig.get().find(({ Route }) => route === Route);
    // TODO: return false below when all routes have been handled, this is currently a relaxed condition.
    if (!ui) return false;
    const uiIsOnRole = role.UIs.find((access) => access.Name == ui.Name);
    return !!uiIsOnRole;
  }
}
