export enum UIActions {
  CreateShipment = 'CreateShipment',
  ExportShipment = 'ExportShipment',
  UpdateShipment = 'UpdateShipment',

  CreateDevice = 'CreateDevice',
  ImportDevice = 'ImportDevice',
  ExportDevice = 'ExportDevice',
  UpdateDevice = 'UpdateDevice',
  ViewUploadHistory = 'ViewUploadHistory',
  SwapIMEI = 'SwapIMEI',
  SendBackToAgent = 'SendBackToAgent',

  CreateCategory = 'CreateCategory',
  ImportCategory = 'ImportCategory',
  ExportCategory = 'ExportCategory',
  UpdateCategory = 'UpdateCategory',

  CreateProduct = 'CreateProduct',
  ImportProduct = 'ImportProduct',
  ExportProduct = 'ExportProduct',
  UpdateProduct = 'UpdateProduct',

  ApproveAgentRequest = 'ApproveAgentRequest',
  ExportAgentRequest = 'ExportAgentRequest',
  UpdateAgentRequest = 'UpdateAgentRequest',

  ApproveUserRequest = 'ApproveUserRequest',
  ExportUserRequest = 'ExportUserRequest',
  LockDevice = 'LockDevice',
  UnlockDevice = 'UnlockDevice',
  RecallDevice = 'RecallDevice',
  CreateUser = 'CreateUser',
  ImportUser = 'ImportUser',
  ExportUser = 'ExportUser',
  UpdateUser = 'UpdateUser',
  DeleteUser = 'DeleteUser',

  CreateBusinessLocation = 'CreateBusinessLocation',
  ImportBusinessLocation = 'ImportBusinessLocation',
  ExportBusinessLocation = 'ExportBusinessLocation',
  UpdateBusinessLocation = 'UpdateBusinessLocation',
  DeleteBusinessLocation = 'DeleteBusinessLocation',

  ExportReport = 'ExportReport',

  CreateRole = 'CreateRole',
  EditRole = 'EditRole',
  CreateStakeholder = 'CreateStakeholder',
  ImportStakeholder = 'ImportStakeholder',
  ExportStakeholder = 'ExportStakeholder',
  UpdateStakeholder = 'UpdateStakeholder',
  DeleteStakeholder = 'DeleteStakeholder',

  CreateEscalation = 'CreateEscalation',
  ImportEscalation = 'ImportEscalation',
  ExportEscalation = 'ExportEscalation',
  UpdateEscalation = 'UpdateEscalation',
  DeleteEscalation = 'DeleteEscalation',

  DeviceRepairAction = 'DeviceRepairAction',
  ExportRepairRequest = 'ExportRepairRequest',

  ExportAgent = 'ExportAgent',
  LoadWithLocation = 'LoadWithLocation',
  WithdrawDevice = 'WithdrawDevice',
  SendForRepair = 'SendForRepair',
  ReassignAgents = 'ReassignAgents',

  ExportActivityLog = 'ExportActivityLog',

  ApproveAgentReassignment = 'ApproveAgentReassignment',
}
