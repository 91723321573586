import { EventEmitter, Injectable, Output } from '@angular/core';
import { interval, Observable, Subscription, timer } from 'rxjs';
import { map, shareReplay, takeUntil } from 'rxjs/operators';
import { Timer } from '../timer';

@Injectable({
  providedIn: 'root',
})
export class IdleTimeoutService {
  private timer: Timer;
  private period: number=0;
  private subscription?: Subscription;
  @Output() timeOut: EventEmitter<boolean> = new EventEmitter<boolean>();

  restart() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.startCountDown(--this.period);
  }

  startCountDown(period: number) {
    this.period = ++period;
    this.timer = new Timer(new Date(), this.period);
    this.timeLeft$ = interval(1000).pipe(
      takeUntil(timer(1000 * this.period * 60)),
      map(x => this.timer.calcDateDiff(),
        shareReplay(1)
      ));

    this.subscription = this.timeLeft$.subscribe((tv: number) => {
      // console.log(tv);
      if (tv == 0) {
        if (this.subscription) {
          this.subscription.unsubscribe();
        }
        this.timeOut.emit(true);
      }
    });
  }

  private timeLeft$: Observable<number> ;
}
