import { Directive, ElementRef, Input } from '@angular/core';
import { User } from 'src/app/models/User';
import Store, { AppKey } from 'src/app/shared/store';

@Directive({
  selector: '[authorize]',
})
export class AuthorizationDirective {
  store = new Store();
  @Input('ui') ui: string = '';
  @Input('action') action?: string = '';
  @Input('display') display?: string = '';
  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    var user = this.store.get(AppKey.USER) as User;
    this.elementRef.nativeElement.style.display =
      user && user.IsSeeded ? (this.display ? this.display : 'block') : 'none';
    this.checkAccess();
  }
  checkAccess() {
    var user = this.store.get(AppKey.USER) as User;
    if (user && user.Role) {
      const found = user.Role.UIs.find((access) =>
        access.Name == this.ui && this.action
          ? access.Actions.find((actn) => actn.Name == this.action)
          : true
      );
      this.elementRef.nativeElement.style.display = found
        ? this.display
          ? this.display
          : 'block'
        : 'none';
    }
  }
}
