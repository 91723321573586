import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitPascal',
})
export class SplitPascalPipe implements PipeTransform {
  // transform(value: unknown, ...args: unknown[]): unknown {
  //   return null;
  // }

  transform(word: string): unknown {
    var wordRe = /($[a-z])|[A-Z][^A-Z]+/g;
    return word.match(wordRe)?.join(' ') || word;
  }
}
