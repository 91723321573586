import { UIActions } from './UIActions';
import { UIRoutes } from './UIRoutes';
import { UIs } from './UIs';

export class AuthorizationConfig {
  static get() {
    return [
      {
        Name: UIs.Dashboard,
        Route: UIRoutes.Dashboard,
        Actions: [],
      },

      {
        Name: UIs.Shipment,
        Route: UIRoutes.Shipment,
        Actions: [
          { Name: UIActions.CreateShipment },
          { Name: UIActions.ExportShipment },
          { Name: UIActions.UpdateShipment },
        ],
      },

      {
        Name: UIs.Device,
        Route: UIRoutes.Device,
        Actions: [
          { Name: UIActions.CreateDevice },
          { Name: UIActions.ImportDevice },
          { Name: UIActions.ExportDevice },
          { Name: UIActions.UpdateDevice },
          { Name: UIActions.ViewUploadHistory },
          { Name: UIActions.SwapIMEI },
          { Name: UIActions.LockDevice },
          { Name: UIActions.UnlockDevice },
          { Name: UIActions.SendBackToAgent },
        ],
      },

      {
        Name: UIs.DeviceUpload,
        Route: UIRoutes.DeviceUpload,
        Actions: [],
      },


      {
        Name: UIs.Category,
        Route: UIRoutes.Category,
        Actions: [
          { Name: UIActions.CreateCategory },
          { Name: UIActions.ImportCategory },
          { Name: UIActions.ExportCategory },
          { Name: UIActions.UpdateCategory },
        ],
      },

      {
        Name: UIs.Product,
        Route: UIRoutes.Product,
        Actions: [
          { Name: UIActions.CreateProduct },
          { Name: UIActions.ImportProduct },
          { Name: UIActions.ExportProduct },
          { Name: UIActions.UpdateProduct },
        ],
      },

      {
        Name: UIs.AgentApproval,
        Route: UIRoutes.AgentApproval,
        Actions: [
          { Name: UIActions.ApproveAgentRequest },
          { Name: UIActions.UpdateAgentRequest },
          { Name: UIActions.ExportAgentRequest },
        ],
      },

      {
        Name: UIs.UserApproval,
        Route: UIRoutes.UserApproval,
        Actions: [
          { Name: UIActions.ApproveUserRequest },
          { Name: UIActions.ExportUserRequest },
        ],
      },

      {
        Name: UIs.User,
        Route: UIRoutes.User,
        Actions: [
          { Name: UIActions.CreateUser },
          { Name: UIActions.ImportUser },
          { Name: UIActions.ExportUser },
          { Name: UIActions.UpdateUser },
          { Name: UIActions.DeleteUser },
        ],
      },

      {
        Name: UIs.BusinessLocation,
        Route: UIRoutes.BusinessLocation,
        Actions: [
          { Name: UIActions.CreateBusinessLocation },
          { Name: UIActions.ImportBusinessLocation },
          { Name: UIActions.ExportBusinessLocation },
          { Name: UIActions.UpdateBusinessLocation },
          { Name: UIActions.DeleteBusinessLocation },
        ],
      },

      {
        Name: UIs.Report,
        Route: UIRoutes.Report,
        Actions: [{ Name: UIActions.ExportReport }],
      },

      {
        Name: UIs.Role,
        Route: UIRoutes.Role,
        Actions: [{ Name: UIActions.CreateRole }],
      },

      {
        Name: UIs.Stakeholder,
        Route: UIRoutes.Stakeholder,
        Actions: [
          { Name: UIActions.CreateStakeholder },
          { Name: UIActions.ImportStakeholder },
          { Name: UIActions.ExportStakeholder },
          { Name: UIActions.UpdateStakeholder },
          { Name: UIActions.DeleteStakeholder },
        ],
      },

      {
        Name: UIs.Escalation,
        Route: UIRoutes.Escalation,
        Actions: [
          { Name: UIActions.CreateEscalation },
          { Name: UIActions.ImportEscalation },
          { Name: UIActions.ExportEscalation },
          { Name: UIActions.UpdateEscalation },
          { Name: UIActions.DeleteEscalation },
        ],
      },
      {
        Name: UIs.Agents,
        Route: UIRoutes.Agent,
        Actions: [
          { Name: UIActions.RecallDevice },
          { Name: UIActions.ExportAgent },
          { Name: UIActions.WithdrawDevice },
          { Name: UIActions.LoadWithLocation },
          { Name: UIActions.SendForRepair },
          { Name: UIActions.ReassignAgents },
        ],
      },
      {
        Name: UIs.RepairApproval,
        Route: UIRoutes.RepairApproval,
        Actions: [
          { Name: UIActions.ExportRepairRequest },
          { Name: UIActions.DeviceRepairAction },
        ],
      },
      {
        Name: UIs.UserActivityLog,
        Route: UIRoutes.UserActivityLog,
        Actions: [
          { Name: UIActions.ExportActivityLog },
        ],
      },
      {
        Name: UIs.AgentReassignmentApproval,
        Route: UIRoutes.AgentReassignmentApproval,
        Actions: [
          { Name: UIActions.ApproveAgentReassignment },
        ],
      },
      //add other UIs with their actions
    ];
  }
}
