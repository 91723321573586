import { NgModule } from '@angular/core';
import { RouterModule, Routes, mapToCanActivate } from '@angular/router';
import { AuthGuard } from './shared/authGuard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    // canActivate: mapToCanActivate([AuthGuard]),
    path: 'login',
    redirectTo: 'auth/login',
    // loadChildren: () =>
    //   import('../app/views/stand-alone/login/login.moule').then((m) => m.LoginModule),
  },
  {   
    canActivate: [],
    path: 'shipment-approval/:mail/:shipment',
    loadChildren: () =>
      import('../app/views/stand-alone/shipment-approval/shipment-approval.module').then(
        (m) => m.ShipmentApprovalModule
      ),
  },
  {
    // canActivate: mapToCanActivate([AuthGuard]),
    path: 'request-access',
    redirectTo: 'auth/request-access',
    // loadChildren: () =>
    //   import('../app/views/stand-alone/user-request/user-request.module').then(
    //     (m) => m.UserRequestModule
    //   ),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./core/components/auth/auth.module').then(
        (m) => m.AuthModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./core/components/mainpage/mainpage.module').then(
        (m) => m.MainpageModule
      ),
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes,
    { enableTracing: true } // <-- debugging purposes only
    )
  ],
  exports: [RouterModule],
  providers:[AuthGuard]
})
export class AppRoutingModule { }
