export class Timer {
  startDate: Date;
  endDate: Date;
  /**
   *
   */
  constructor(start: Date, period: number) {
    this.startDate = start;
    this.endDate = this.getByDay(period)
  }

  calcDateDiff(): number {
    const dDay = this.endDate.valueOf();

    const milliSecondsInASecond = 1000;
    const minutesInAnHour = 60;
    const secondsInAMinute = 60;

    const timeDifference = dDay - Date.now();


    const minutesToDday = Math.floor(
      (timeDifference / (milliSecondsInASecond * minutesInAnHour)) %
      secondsInAMinute
    );


    return minutesToDday;
  }

  private getByDay(period: number): Date {
    var mins = this.startDate.getMinutes();
    return new Date(
      this.startDate.getFullYear(),
      this.startDate.getMonth(),
      this.startDate.getDate(),
      this.startDate.getHours(),
      mins + period,
      this.startDate.getSeconds());
  }
}
