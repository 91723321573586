export interface Column<T> {
  field: keyof T;
  header: string;
}

export enum ColumnType {
  Date,
  String,
  Currency,
  Number,
  Boolean,
}

export interface ColumnConfig<T> {
  header: string;
  field: ((datum: T) => Date | string | number | undefined) | keyof T;
  ngClass?: (
    datum: T
  ) => string | string[] | Set<string> | { [klass: string]: boolean };
  columnType?: ColumnType;
  default?: boolean;
  DontSort?:boolean
  sortField?: keyof T;
  onClick?: (event: any, datum:T) => void;
}

export interface DataConverter<T, K> {
  out: (datum: T) => K;
  in: (datum: K) => T;
}
