import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Role } from 'src/app/models/Role';
import { UIActions } from 'src/app/models/UIActions';
import { User } from 'src/app/models/User';
import { PermissionService } from 'src/app/shared/services/permission/permission.service';
import Store, { AppKey } from 'src/app/shared/store';

@Directive({
  selector: '[isAllowed]',
})
export class IsAllowedDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permSvc: PermissionService
  ) {}
  @Input() set isAllowed(action: UIActions) {
    this.isGranted(action);
  }

  // @Input() isAllowed: UIActions;

  private isGranted(action: UIActions) {
    // console.log(action, this.permSvc.hasPermission(action));
    if (this.permSvc.hasActionPermission(action)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
