import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import Store, { AppKey } from "./store";
import { PermissionService } from "./services/permission/permission.service";
import { UIRoutes } from "../models/UIRoutes";
@Injectable({providedIn: 'root'})
export class AuthGuard  {
  store = new Store();
  constructor(private router: Router, private permSvc: PermissionService) {

  }
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if(!this.store.get(AppKey.USER))
    {
      if(state.url.split('?')[0].endsWith('/login') || state.url.endsWith('/request-access'))
      {
        return true;
      } else {
        const param = {returnUrl: state.url}
        this.router.navigate(["/login"], { queryParams: param})
        return false;
      }
    }
    const routeName = state.url.split('/')[1].split('?')[0] as UIRoutes;
    return routeName ? this.permSvc.canAccessRoute(routeName) : true;
    // return true;
    // const routeName = state.url.split('/')[1].split('?')[0] as UIRoutes;
    // return routeName ? this.permSvc.canAccessRoute(routeName) : true;
  }
}
